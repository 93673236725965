import { json } from "aurelia-fetch-client";
import { autoinject } from "aurelia-dependency-injection";
import { Router } from "aurelia-router";
import { SingletonService } from "singleton";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { I18N } from "aurelia-i18n";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";

@autoinject
export class ReauthorizeUsers {
  private bank;
  private isOurConditionsAccepted: boolean;
  private isYapilyConditionsAccepted: boolean;
  private isError: boolean;
  private shaking: boolean = false;
  private errorMessage: string;
  private authorized_button: any;
  private isLoading: boolean;

  constructor(
    private router: Router,
    private singleton: SingletonService,
    private yapilyHttpClient: YapilyHttpClient,
    private i18n: I18N
  ) {
  }

  activate(account) {
    this.bank = account;
  }

  /**
   * This function checks that the conditions are accepted and attempts
   * to create an agreement towards Yapily
   * @returns the concluded agreement between User Banka and Yapily
   */
  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }
  async makeAgreement() {
    this.isLoading = true;
    this.isError = false;
    if (!this.isOurConditionsAccepted || !this.isYapilyConditionsAccepted) {
      this.isError = true;
      this.errorMessage =
        "Vous devez accepter les conditions pour utiliser nos services.";
      return;
    }

    let method = "PATCH";
    let body = json({
      institutionId: this.bank.selectedAccount.name,
    });
    let params = {
      method: method,
      body: body,
    };

    let requests = await this.yapilyHttpClient.fetch(
      "/yapily/account-auth-requests",
      params
    );



    let response;

    try {
      let is200OK = await checkResponseStatus(requests);
      response = await is200OK.json();
    } catch (response) {
      if (response.code >= 400) {
        this.isError = true;
        this.errorMessage = this.i18n.tr("home.error.reauthorize_users_error_error");
        this.isLoading = false;
      }
    }

    let agreement: any = response;
    this.goToYapily(agreement.data.authorisationUrl);
  }
  
  async goToYapily(url: string) {
    window.location.replace(url);
  }

  goBack() {
    this.router.navigateToRoute("home");
  }
}
